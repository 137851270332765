// Place all the styles related to the identities controller here.
// They will automatically be included in application.css.
// You can use Sass (SCSS) here: http://sass-lang.com/

#create-account {
  .control-group {
    margin-bottom: 0;
  }

  .span6:first-child {
    padding-right: 18px;
  }

  .input-large {
    height: 35px;
    width: 100%;
    box-sizing : border-box;
  }

  .form-fields {
    background-color: $background-grey-color;
    padding: 15px;
  }

  .btn {
    min-height: 40px;
    width: 60%;
  }
}

.registration-form {
  background: $white-color;
  border: 1px solid $border-color;
  padding: 20px 50px 0px 50px;

  .btn-white {
    background: $white-color;
  }
}
