
/* bold these header fonts (when focused) for IE 10 as the outline is hard to see. */
html[data-useragent*='MSIE 10.0'] table.collapsible_headers th:focus {
  font-weight: bold;
}

/* table cells (when focused) for IE 10 not outline add  one.  */
html[data-useragent*='MSIE 10.0'] tr:focus {
  outline: 1px solid black;
}

/* if there is a header embedded in a legend just inherit the styles.
 * This is a 508 nitpick for screen readers and people that turn off css. */
legend h1,
legend h2,
legend h3,
legend h4,
legend h5,
legend h6 {
  display: inline;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  text-shadow:inherit;
}

.nowrap {
  white-space: nowrap
}

/* text only for screen readers. */
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
